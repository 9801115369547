import { FloatingArrow, FloatingPortal, Placement } from "@floating-ui/react";
import classNames from "classnames";
import { ReactNode } from "react";

import styles from "./FloatingTooltip.module.scss";

import useCustomFloating from "~/hooks/useCustomFloating";

const cx = classNames.bind(styles);

type FloatingTooltipProps = {
  children: ReactNode;
  tooltipContent: ReactNode;
  placement?: Placement;
  useMaxWidth?: boolean;
  showForDebugging?: boolean;
};

const FloatingTooltip = ({
  children,
  tooltipContent,
  placement = "top",
  useMaxWidth = true,
  showForDebugging
}: FloatingTooltipProps) => {
  const {
    refs,
    floatingStyles,
    getReferenceProps,
    getFloatingProps,
    arrowRef,
    context,
    isOpen
  } = useCustomFloating({ placement });

  const showTooltip = showForDebugging ? true : isOpen;

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </div>

      <FloatingPortal>
        {showTooltip && (
          <div
            className={cx(styles.tooltip, {
              [styles.maxWidth]: useMaxWidth
            })}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {tooltipContent}
            <FloatingArrow ref={arrowRef} context={context} fill="white" />
          </div>
        )}
      </FloatingPortal>
    </>
  );
};

export default FloatingTooltip;
