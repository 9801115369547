import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { CareManagerTaskList } from "./components/careManagerTasks/CareManagerTaskList";
import ProgramCard from "./components/programCard/ProgramCard";
import styles from "./ProgramsOverviewPage.module.scss";

import { programTypeFilterOptions } from "../program/programUsers/filters/filterOptions";

import HospitalShield from "~/assets/hospital-shield.svg";
import NoProgramsSidekick from "~/assets/sidekick-jumping.png";
import Heading from "~/components/heading/Heading";
import Layout from "~/components/layout/Layout";
import CustomSelect from "~/components/select/CustomSelect";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import SkeletonProgramCard from "~/components/skeletons/SkeletonProgramCard";
import config from "~/config";
import { DEFAULT_FILTER_VALUE } from "~/constants/filters";
import { UserPrivileges } from "~/constants/privileges";
import { SegmentationKey } from "~/constants/segmentationKeys";
import getMergedPrograms from "~/helpers/program/getMergedPrograms";
import { displayErrorToast } from "~/helpers/toast/displayToast";
import { useAuthUserSpecialty } from "~/hooks/useApi/specialties/useSpecialties";
import { useFeatureToggle } from "~/hooks/useApi/useFeatureToggle";
import usePrograms from "~/hooks/useApi/usePrograms";
import useUser from "~/hooks/useUser";
import { setNavBanner } from "~/state/navHeaderNotification/navHeaderSlice";
import { RootState } from "~/state/store";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { Program } from "~/typing/sidekickTypes";

export default function ProgramsOverviewPage() {
  const { t } = useTranslation();
  const { programs, isLoading, invalidate: invalidatePrograms } = usePrograms();
  const { user } = useUser();
  const [programTypeFilterValue, setProgramTypeFilterValue] = useState(
    programTypeFilterOptions.find(
      (option) => option.value === DEFAULT_FILTER_VALUE
    )?.value
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const storedLocales =
    useSelector((state: RootState) => state.programLocaleNav) || {};

  const { trackMyPogramsPageViewed } = useAmplitudeTracking();

  const { isEnabled: careTeamFeatureRoleEnabled } = useFeatureToggle({
    featureName: SegmentationKey.CareTeamRole
  });

  const { isEnabled: nextStepEnabled } = useFeatureToggle({
    featureName: SegmentationKey.CoachCentricView,
    userId: user?.userId
  });

  const getPathEnding = () => {
    if (user?.privileges === undefined) {
      return "";
    }

    switch (parseInt(user?.privileges)) {
      case UserPrivileges.Compliance:
        return "/compliance/users";
      case UserPrivileges.Provider:
        return "/provider/users";
      default:
        return "";
    }
  };

  const shouldDisplayFilters = () => {
    return (
      programs?.some(
        (program) =>
          program.programCatalogItemProjectType?.toLowerCase() === "test"
      ) &&
      programs?.some(
        (program) =>
          program.programCatalogItemProjectType?.toLowerCase() !== "test"
      )
    );
  };

  const getFilteredPrograms = (mergedPrograms: Program[]) => {
    if (!shouldDisplayFilters()) {
      return mergedPrograms;
    }

    switch (programTypeFilterValue) {
      case DEFAULT_FILTER_VALUE:
        return mergedPrograms;
      case "live":
        return mergedPrograms?.filter(
          (program) =>
            program.programCatalogItemProjectType?.toLowerCase() !== "test"
        );
      case "test":
        return mergedPrograms?.filter(
          (program) =>
            program.programCatalogItemProjectType?.toLowerCase() === "test"
        );
      default:
        return mergedPrograms;
    }
  };

  const mergedPrograms = getMergedPrograms(programs, config.isAnthem);
  const filteredPrograms = getFilteredPrograms(mergedPrograms);

  const {
    hasError: specialtyHasError,
    isLoading: specialtyLoading
  } = useAuthUserSpecialty({
    authUserId: user?.id || user?.userId || "",
    enabled: careTeamFeatureRoleEnabled
  });

  useEffect(() => {
    if (specialtyLoading || !config.isAnthem) return;

    if (specialtyHasError && careTeamFeatureRoleEnabled) {
      dispatch(
        setNavBanner({
          message: t("roles.selectValidRole"),
          action: {
            text: t("commands.clickHere"),
            onClick: () => navigate("/me")
          },
          type: "warning"
        })
      );
    }
  }, [careTeamFeatureRoleEnabled, specialtyLoading, specialtyHasError]);

  useEffect(() => {
    trackMyPogramsPageViewed();
  }, []);

  const [toastDisplayed, setToastDisplayed] = useState(false);

  // If an errorMsg query param is present, display an error toast after the component mounts.
  // This is used when redirecting from the Elevance SSO when a user is not found.
  useEffect(() => {
    if (!toastDisplayed) {
      setToastDisplayed(true);
      const urlSearchParams = new URLSearchParams(window.location.search);
      const errorMsg = urlSearchParams.get("errorMsg");

      if (errorMsg) {
        setTimeout(() => {
          displayErrorToast({ message: errorMsg, shouldDisappear: true });
          urlSearchParams.delete("errorMsg");
          window.history.replaceState({}, "", `${window.location.pathname}`);
        }, 200);
      }
    }
  }, [toastDisplayed]);

  return (
    <SentryErrorBoundary
      resetState={invalidatePrograms}
      transactionName="ProgramOverviewPage"
    >
      <Layout>
        <Helmet title={t("programs.myPrograms")} defer={false} />
        {nextStepEnabled && <CareManagerTaskList />}

        <div className={styles.overviewHeading}>
          <div className={styles.title}>
            <img
              className={styles.titleImage}
              src={HospitalShield}
              alt="Decorative logo"
              height="24"
              width="24"
            />
            <Heading level="h2">{t("programs.myPrograms")}</Heading>
          </div>

          {shouldDisplayFilters() && (
            <CustomSelect
              value={programTypeFilterValue}
              valueKey="value"
              onChange={(e) => setProgramTypeFilterValue(e.target.value)}
              renderOption={(option) => option.text}
              options={programTypeFilterOptions}
              className={styles.select}
              dataTestId="program-overview-select"
              allowSearch
            />
          )}
        </div>
        {isLoading ? (
          <ul className={styles.programs}>
            {Array.from(Array(6).keys()).map((index) => (
              <li key={index}>
                <SkeletonProgramCard />
              </li>
            ))}
          </ul>
        ) : programs && programs.length > 0 ? (
          <ul className={styles.programs}>
            {filteredPrograms?.map((program) => {
              const { programCatalogItemId, locale } = program;
              const storedLocale =
                storedLocales[programCatalogItemId]?.selectedLocale || locale;
              const pathEnding = getPathEnding();
              return (
                <li key={`${programCatalogItemId}-${storedLocale}`}>
                  <Link
                    to={`/program/${programCatalogItemId}/locale/${storedLocale}${pathEnding}`}
                    tabIndex={0}
                    className={styles.link}
                  >
                    <ProgramCard program={program} />
                  </Link>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className={styles.noPrograms}>
            <img src={NoProgramsSidekick} />
            <h1 className={styles.heading}>
              {t("programs.noPrograms.header")}
            </h1>
            <p className={styles.details}>{t("programs.noPrograms.details")}</p>
            <p className={styles.pending}>{t("programs.noPrograms.pending")}</p>
          </div>
        )}
      </Layout>
    </SentryErrorBoundary>
  );
}
