import { DateTime } from "luxon";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Markdown, { defaultUrlTransform } from "react-markdown";
import remarkGfm from "remark-gfm";

import styles from "./Message.module.scss";
import { MessageListContext } from "./MessageList.context";
import { shouldBeReadByAuthenticatedUser } from "./MessageWindow.helpers";

import { readOtherCoachMessage } from "~/api/requests/messageRequests";
import ThinCheckMark from "~/assets/svgComponents/ThinCheckMark";
import Avatar from "~/components/avatar/Avatar";
import Notification from "~/components/notification/Notification";
import Options from "~/components/options/Options";
import TranslationToggleBox from "~/components/translationToggleBox/TranslationToggleBox";
import config from "~/config";
import { TimeInMs } from "~/constants/measurements";
import { useAutoTranslate } from "~/contexts/translationContext/useAutoTranslate";
import humanizeTimeDiff from "~/helpers/date/humanizeTimeDiff";
import getMessageHtml, { highlightSubstring } from "~/helpers/getMessageHtml";
import { urlRegex } from "~/helpers/getMessageMarkdown";
import { copyToClipboard } from "~/helpers/util/util";
import { TaskPageContext } from "~/pages/nextStep/TaskPage.context";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { ChatMessage } from "~/typing/sidekickTypes";

type MessageProps = {
  message: ChatMessage;
  repliedMessage?: boolean;
  user: { fullName: string; imageHref?: string; userId?: string; id?: string };
  isHighestRead?: boolean;
};

const Message = ({
  message,
  repliedMessage,
  user,
  isHighestRead
}: MessageProps) => {
  const messageRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const { newNextStep } = useContext(TaskPageContext);
  const {
    usersForAvatars,
    authUser,
    setMessageBeingRepliedTo,
    unreadMessages,
    program,
    readMessageOnView,
    searchQuery
  } = useContext(MessageListContext);

  const {
    trackMessageReplySelected,
    trackMessageCopySelected
  } = useAmplitudeTracking();

  const avatarUser =
    usersForAvatars[
      message.replyingUserId ? message.replyingUserId : message.senderUserId
    ];

  const createdDate = DateTime.fromISO(message.createdDate);
  const seenDate = message.seenDate
    ? DateTime.fromISO(message.seenDate)
    : undefined;
  const isRead = message.seenDate !== undefined;

  const isCoachReceiver =
    message.senderUserId === user?.id || message.senderUserId === user?.userId;

  const showReadReceipt = isRead && !isCoachReceiver;

  const [showOptions, setShowOptions] = useState(false);
  // Whether the translation is enabled or not
  const { autoTranslate } = useAutoTranslate();
  // Whether the user wants to show the translated text or not
  const [showTranslated, setShowTranslated] = useState(true);
  const handleToggleTranslation = () => {
    setShowTranslated(!showTranslated);
  };

  const showOptionsIfPossible = () => {
    if (isCoachReceiver) {
      setShowOptions(true);
    }
  };

  const getNotificationStyle = () => {
    const style = styles.seenNotification;

    const unreadMessage = unreadMessages?.unreadMessages.find(
      (unread) => unread.id === message.id
    );

    if (unreadMessage?.toAuthUser) {
      return style;
    }
    if (unreadMessage?.unreadByAuthUser) {
      return style + ` ${styles.nonRecipientNotSeen}`;
    }

    return style + ` ${styles.nonRecipientSeen}`;
  };

  const getDataTestId = () => {
    if (
      message.senderUserId === authUser?.id ||
      message.replyingUserId === authUser?.id
    ) {
      return "message-sent";
    } else if (repliedMessage) {
      return "message-replied";
    } else if (isCoachReceiver) {
      return "message-received";
    } else {
      return "message-sent";
    }
  };

  let classes = styles.message;
  if (newNextStep) {
    classes += ` ${styles.newNextStep} ${getNotificationStyle()}`;
  }
  if (
    message.senderUserId === authUser?.id ||
    message.replyingUserId === authUser?.id
  ) {
    // Message sent from coach to user
    classes += ` ${styles.sent}`;
  } else if (repliedMessage) {
    classes += ` ${styles.replied}`;
  } else if (isCoachReceiver) {
    // Message sent from user to coaches
    classes += ` ${styles.received}`;
  } else {
    // Message sent from other coach to user
    classes += ` ${styles.sent} ${styles.otherCoach}`;
  }

  // it is possible we get negative time here, if server is on different time than client
  const diff = Math.max(Date.now() - createdDate.toMillis());

  const createdTime = createdDate
    .setLocale(config.isAnthem ? "en-us" : "en-GB")
    .toLocaleString({
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit"
    });

  const tooltipTime = createdDate
    .setLocale(config.isAnthem ? "en-us" : "en-GB")
    .toLocaleString({
      weekday: "long",
      month: "long",
      day: "2-digit",
      year: "numeric"
    });
  const seenTime = seenDate
    ?.setLocale(config.isAnthem ? "en-us" : "en-GB")
    .toLocaleString({
      weekday: "long",
      month: "long",
      day: "2-digit",
      year: "numeric"
    });

  const messageIsNotRead = !repliedMessage && !isRead && isCoachReceiver;

  const getCoachName = () => {
    return (message.replyingUserId || message.senderUserId) === authUser?.id
      ? t("general.you")
      : usersForAvatars[message.replyingUserId || message.senderUserId]
          ?.displayName;
  };

  const getRecipientName = () => {
    return message.recipientUserId === authUser?.id
      ? t("general.you")
      : usersForAvatars[message.recipientUserId]?.displayName;
  };

  const getCoachTitle = () => {
    if (
      (message.replyingUserId || message.senderUserId) !==
      (authUser?.id || authUser?.userId)
    ) {
      return (
        usersForAvatars[message.replyingUserId || message.senderUserId]
          ?.title ?? ""
      );
    }
    return "";
  };

  const getOptionsStyle = () => {
    const positionFromTop = messageRef?.current?.getBoundingClientRect().top;
    const messageWidth =
      (messageRef?.current?.getBoundingClientRect().right ?? 0) -
      (messageRef?.current?.getBoundingClientRect().left ?? 0);
    const windowHeight = window.innerHeight;

    const offSetToBottom = 300;
    const messageWidthThreshold = 200;

    let classes = `${styles.options} `;

    if (positionFromTop && windowHeight - positionFromTop < offSetToBottom) {
      classes += `${styles.bottom} `;
    } else {
      classes += `${styles.top} `;
    }

    if (messageWidth && messageWidth > messageWidthThreshold) {
      classes += `${styles.right} `;
    } else {
      classes += `${styles.left} `;
    }
    return classes;
  };

  const processURL = (url: string) => {
    // if the url is a maps magnet link for maps/mail/phone, just return it as it is
    if (
      url.startsWith("mailto:") ||
      url.startsWith("tel:") ||
      url.startsWith("maps:")
    ) {
      return url;
    }
    const safeURL = defaultUrlTransform(url);

    return safeURL.replace(urlRegex, (urlStr) => {
      return urlStr.match(/https?:\/\//) ? urlStr : `https://${urlStr}`;
    });
  };

  const renderMessageLink = ({ href, children }) => {
    return (
      <a href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  };

  const renderHighlightedSubstring = (children) => {
    const text = highlightSubstring(children as string, searchQuery);

    if (typeof text === "string") {
      return <code>{text.replace(/`/g, "")}</code>;
    }

    if (Array.isArray(text)) {
      return (
        <code>
          {text.map((el) =>
            typeof el === "string" ? el.replace(/`/g, "") : el
          )}
        </code>
      );
    }
  };

  useEffect(() => {
    if (!readMessageOnView) return;

    if (shouldBeReadByAuthenticatedUser(message, user?.userId ?? "")) {
      readOtherCoachMessage(
        program.programCatalogItemId,
        program.locale,
        user?.userId ?? "",
        message.id
      );
    }
  }, [message]);

  const scrollToOriginalMessage = () => {
    const element = document.getElementById(`original-${message.id}`);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Check if translatedText is available and not empty
  const hasTranslatedText = Boolean(
    message.translatedText && message.translatedText.trim() !== ""
  );

  // Determine which text to display
  const displayText =
    autoTranslate && showTranslated && hasTranslatedText
      ? message.translatedText
      : message.text;

  const messageWithHighlightedSubstring = useMemo(() => {
    return highlightSubstring(displayText || "", searchQuery, true);
  }, [searchQuery, displayText]);

  return (
    <div
      className={classes}
      onMouseEnter={() => showOptionsIfPossible()}
      onMouseLeave={() => setShowOptions(false)}
      onClick={repliedMessage ? scrollToOriginalMessage : undefined}
      id={repliedMessage ? `replied-${message.id}` : `original-${message.id}`}
      data-testid={getDataTestId()}
    >
      {!newNextStep && !repliedMessage && (
        <Avatar size="sm" user={avatarUser} title={avatarUser?.fullName} />
      )}
      <div data-testid="message-wrapper" className={styles.messageWrapper}>
        {!newNextStep &&
          !repliedMessage &&
          unreadMessages?.unreadMessages?.some(
            (unread) => unread.id === message.id
          ) && (
            <Notification
              size="xs"
              count={1}
              className={getNotificationStyle()}
            />
          )}
        <div
          data-testid="message-text-wrapper"
          className={styles.textWrapper}
          ref={messageRef}
        >
          {!newNextStep ? (
            <>
              {isCoachReceiver && (
                <div className={styles.metadata}>
                  <span>{`${t("general.to")} `}</span>
                  <span
                    className={`${styles.coachName} ${
                      message.recipientUserId === authUser?.id
                        ? styles.authUserRecipient
                        : ""
                    }`}
                  >
                    {`${getRecipientName()}`}
                  </span>
                </div>
              )}
              {!isCoachReceiver && (
                <div className={styles.coach}>
                  <span className={styles.coachName}>{getCoachName()}</span>
                  <span>{` ${getCoachTitle()}`}</span>
                </div>
              )}
            </>
          ) : null}
          <div
            className={`${styles.text} ${
              messageIsNotRead ? styles.notRead : ""
            }`}
          >
            {isCoachReceiver ? (
              getMessageHtml(displayText, searchQuery)
            ) : (
              <Markdown
                remarkPlugins={[remarkGfm as any]}
                urlTransform={processURL}
                components={{
                  a: ({ href, children }) =>
                    renderMessageLink({ href, children }),
                  code: ({ children }) => renderHighlightedSubstring(children)
                }}
              >
                {messageWithHighlightedSubstring as string}
              </Markdown>
            )}
          </div>
          {hasTranslatedText && autoTranslate && (
            <TranslationToggleBox
              showTranslated={showTranslated}
              handleToggleTranslation={handleToggleTranslation}
            />
          )}
        </div>
        {!repliedMessage && (
          <div className={styles.metadata}>
            <span className={styles.datestamp} title={tooltipTime}>
              {diff < TimeInMs.Week
                ? humanizeTimeDiff(diff, false)
                : createdTime}
            </span>
            <span className={styles.deliveryStatus}>
              {showReadReceipt && (
                <span title={`${t("general.read")} ${seenTime}`}>
                  <ThinCheckMark />
                </span>
              )}
              {/* We show the read checkmark for all read messages, but only the text for the latest one */}
              {showReadReceipt && isHighestRead && (
                <span title={`${t("general.read")} ${seenTime}`}>
                  {t("general.read")}
                </span>
              )}
            </span>
          </div>
        )}
      </div>
      {showOptions && !repliedMessage && (
        <Options
          dataTestId="message-options"
          className={getOptionsStyle()}
          options={[
            {
              title: t("messages.reply"),
              func: () => {
                trackMessageReplySelected();
                setMessageBeingRepliedTo(message);
              }
            },
            {
              title: t("messages.copyText"),
              func: () => {
                trackMessageCopySelected();
                copyToClipboard(message.text);
              }
            }
          ]}
        />
      )}
    </div>
  );
};

export default Message;
